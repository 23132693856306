import style from "../../styles/home.module.css";
import "animate.css";
import chunk from "chunk";

import {
  // Divider,
  Heading,
  Text,
  Box,
  Flex,
  Image,
  // Fade,
  HStack,
  Link,
  Button,
  ButtonGroup,
  // IconButton,
} from "@chakra-ui/react";
import {
  GrInstagram,
  GrFacebookOption,
  GrTwitter,
  GrMail,
} from "react-icons/gr";
import { TiChevronRight } from "react-icons/ti";
const covers = [
  "hella-nuts-plant-based-podcast",
  "3-blackgeeks-podcast",
  "pour-minds-podcast",
  "the-shoot-from-the-hip-show",
  "get-sacred",
  "spawn-on-me-with-kahlief-adams-a-video-game-podcast",
  "highkey-woke",
  "bonnets-durags-a-pillow-talk-podcast",
  "i-do-it-4-hip-hop-podcast",
  "yes-girl",
  "creepy-tech",
  "ignorant-philosophy",
  "just-us",
  "hung-up-podcast",
  "talk-music-talk-with-boice",
  "the-breakfast-club",
  "black-comics-chat-s-podcast",
  "black-to-business",
  "sippin-and-conversations-with-buddha-babi",
  "bodega-boys",
  "colored-couch-conversations",
  "the-black-pov",
  "doing-enough",
  "salt-for-the-soul-podcast",
  "rich-regular-with-kiersten-and-julien-saunders",
  "hc-pod-original",
  "the-y-a-m-s-podcast",
  "the-combat-jack-show",
  "the-cheat-code",
  "the-walk-in",
  "365-brothers-listening-to-black-men",
  "queen-x-podcast",
  "the-dr-vibe-show",
  "travel-n-sh-t-podcast",
  "the-tre-matthews-podcast",
  "sweats-suits-podcast",
  "willa-wednesdays",
  "don-t-let-my-momma-hear-this",
  "the-corner-podcast",
  "blunt-talk-high-conversations",
  "the-sakita-method",
  "micheaux-mission",
  "the-tomorrow-talk",
  "black-information-network-daily",
  "trailblazers-fm-i-am-black-success",
  "negotiate-anything",
  "the-awakened-soul-podcast",
  "date-with-daddy",
  "the-growing-woman",
  "author-nigel-beckles",
  "black-girl-in-om",
  "brothascomics-podcast",
  "don-t-take-it-personal-podcast",
  "real-happy-mom-podcast",
  "category-is",
  "dear-future-wifey",
  "denzel-washington-is-the-greatest-actor-of-all-time-period",
  "thirst-aid-kit",
  "the-siobhan-j-middleton-podcast",
  "the-successful-diligence-podcast",
  "lovecraft-country-radio",
  "avry-s-sports-show",
  "confession-radio",
  "not-your-african-cliche",
  "the-melanin-exchange",
  "rightnowish",
  "conversations-between-widows",
  "so-let-s-talk-about-it-podcast",
  "no-small-talk-conversation-starters-for-introverts",
  "with-audacity",
  "after-the-whistle",
  "i-am-the-prize",
  "the-happy-black-woman-podcast",
  "hustle-pray-repeat",
  "black-girl-podcast",
  "cwf-network",
  "best-friend-race-podcast",
  "bad-faith",
  "the-tanisha-show-i-don-t-be-listening",
  "everythang-culture",
  "blkhlth",
  "way-up-with-angela-yee",
  "naked-beauty",
  "hidden-brain",
  "the-red-dove",
  "stoopkid-stories",
  "daily-dose-of-dopamine",
  "venturing-deep",
  "come-through-with-rebecca-carroll",
  "beware-the-l-s",
  "from-lagos-with-love",
  "the-no-apology-podcast",
  "black-desserts",
  "the-shade-parade",
  "okay-now-listen",
  "kinda-famous-podcast",
  "black-girl-experience",
  "1619",
  "imperfectly-phenomenal-woman",
  "2-happy-heauxes",
  "the-black-swans-podcast",
  "multifamily-insights",
  "reasonings-with-odessa",
  "meet-us-after-7",
  "the-smart-dumb-podcast",
  "sign-me-up-podcast",
  "the-free-smoke-podcast",
  "work-it-lady-podcast-join-us",
  "let-s-go-together",
  "word-to-your-mama",
  "divergent-perspective",
  "opinions-while-black",
  "faith-to-faith-podcast",
  "busy-being-black",
  "see-the-thing-is",
  "sista-inc-rease",
  "the-author-2-author-podcast",
  "the-new-chitlin-circuit",
  "free-your-mind",
  "the-sheer-resilience-podcast",
  "conversation-con-artists-podcast",
  "the-t-side-podcast",
  "she-really-had-a-baby",
  "after-school-anime-club",
  "the-home-video-hustle",
  "black-work-talk",
  "black-nerd-radio",
  "courting-in-color",
  "cozy-chat-podcast",
  "real-talk-on-godtalk",
  "sweet-talks",
  "nasanta-s-reel",
  "wake-up-call",
  "black-talk-radio-network",
  "for-colored-nerds",
  "big-girl-slay",
  "good-ancestor-podcast",
  "working-title-with-keisean-raines",
  "black-frasier",
  "shanghai-chronicles",
  "two-quick-things",
  "geek-giant-podcast",
  "clock-radio-speakers",
  "black-widow-podcast",
  "life-is-a-trip",
  "the-david-banner-podcast",
  "dem-damn-country-boys",
  "why-we-wander",
  "biracial-unicorns",
  "the-oprah-rose-show",
  "mocha-minutes",
  "music-matters-the-caribbean-edition",
  "the-antidote",
  "meet-the-brave",
  "smut-girls",
  "to-my-sisters",
  "equal-opposites",
  "amerikan-therapy",
  "he-rspective-podcast",
  "she-s-beauty-and-the-beast",
  "njl-a-black-nerd-podcast",
  "unlimited-texts",
  "dj-blaze-radio-show-podcast",
  "queue-points-podcast",
  "inner-hoe-uprising",
  "lyles-movie-files",
  "one-question-leadership-podcast",
  "your-confident-self",
  "i-said-what-i-said",
  "mommifaceted-black-working-mom-podcast",
  "da-real-spit-with-big-dar",
  "nosey-af-podcast",
  "dre-s-geek-philosophy",
  "the-fly-widow",
  "harlem-queen",
  "being-eve-the-podcast",
  "nice-neat-the-podcast",
  "the-salad-toss",
  "positive-philter-podcast",
  "ptg-tv",
  "lit-life-podcast-living-life-autonomously",
  "black-fridays",
  "pour-life-decision",
  "my-wife-is-a-therapist",
  "henny-and-hotwings",
  "the-re-session-podcast-by-jeezy",
  "revision-path",
  "techish",
  "another-round",
  "call-me-crazy",
  "be-well-sis-the-podcast",
  "we-re-all-adults-here-podcast",
  "ratchet-ramblings",
  "sisters-of-sexuality-five-shades-of-play",
  "the-homeowner-prep-podcast",
  "dope-black-mums-podcast",
  "higher-learning-with-van-lathan-and-rachel-lindsay",
  "lunch-and-learn-with-dr-berry",
  "she-well-read",
  "sistas-who-kill-a-true-crime-podcast",
  "runnin-late-podcast",
  "bodega-69",
  "earn-your-leisure",
  "the-vault-classic-music-reviews-podcast",
  "disaster-dating-101",
  "unreasonable-fridays",
  "the-midnight-miracle",
  "man-to-man-a-wellness-series",
  "thirsty-for-therapy",
  "dmst-podcast",
  "the-digital-jamaica-podcast",
  "good-moms-bad-choices",
  "unlikely-critics",
  "teach-the-geek-podcast-public-speaking-for-technical-professionals",
  "mind-body-and-business-podcast-with-maria-more",
  "kids-are-people-too",
  "three-fifs-podcast",
  "music-marriage-podcast",
  "preach-it-sis",
  "touchdowns-and-tangents",
  "the-wonder-of-anime",
  "december-26-er-podcast",
  "the-mama-s-den",
  "cock-tales-dirty-discussions",
  "the-pieces-podcast",
  "committed",
  "this-is-life-with-heather-cornelius",
  "where-do-we-begin",
  "people-of-florida",
  "beneath-the-surface",
  "the-combat-divas-podcast",
  "blacker-than-black-times-infinity",
  "the-black-delegates-podcast",
  "the-big-podcast-with-shaq",
  "drink-champs",
  "talking-it-out",
  "tras-la-voz",
  "2-black-girls-1-rose",
  "the-browngirl-experience",
  "advice-from-a-f-ck-boy",
  "keep-the-channel-open",
  "angela-yee-s-lip-service",
  "cage-against-the-machine-round-zero",
  "urban-x-podcast",
  "the-gourmet-goober",
  "the-people-s-podcast",
  "not-just-another-sex-podcast",
  "driving-the-green-book",
  "swanky-93-3-radio-station",
  "heart-hustle-podcast",
  "black-people-love-paramore",
  "black-girls-talk-sports",
  "black-canadian-creators",
  "yng-blk-hr",
  "best-friend-weekend-podcast",
  "reasonable-ignorance",
  "the-only-one-in-the-room",
  "black-diplomats",
  "zoeview-podcast",
  "build-the-damn-thing",
  "truth-s-table",
  "brown-ambition",
  "firecast-hosted-by-severen-henderson",
  "the-professional-homegirl-podcast",
  "timestorm",
  "voice-of-the-people",
  "bad-and-boozy",
  "i-hate-the-homies",
  "the-fix-podcast",
  "popcorn-finance",
  "affirmative-murder",
  "over-saturated-the-podcast",
  "the-peoples-choice-podcast",
  "not-that-serious",
  "as-it-should-be-with-thamarrah-jones",
  "thoughts-pending",
  "hokage-thots",
  "black-girl-brunch",
  "black-market-reads",
  "whatis-tws",
  "un-2-re",
  "her-morning-coffee-mindset-marketing-coaching-mastery",
  "support-is-sexy-with-elayne-fluker-interviews-with-500-women-entrepreneurs",
  "adultconvos-with-blu",
  "the-outspoken-siblings-podcast",
  "boozy-brunch",
  "the-caged-bird-chronicles",
  "catch-the-tea-podcast",
  "what-they-never-told-us",
  "democracy-in-color-with-steve-phillips",
  "dad-bod-rap-pod",
  "the-interracial-jawn",
  "black-nerd-planet",
  "soul-sistas-sleep-meditations-guided-meditations-for-black-women",
  "don-t-take-it-personal",
  "psalms-to-god",
  "the-parenting-cipher",
  "black-tech-green-money",
  "ratchet-respectable",
  "studio-noize-black-art-podcast",
  "tissue-in-the-tape-the-hip-hop-culture-podcast",
  "the-electorette-podcast",
  "in-killing-color",
  "the-connected-experience",
  "strong-black-legends",
  "item-13-an-african-food-podcast",
  "another-word-with-miss-p",
  "beat-club-podcast",
  "coach-class-podcast",
  "moms-and-martinis",
  "chroniclez-podcast",
  "red-table-talk",
  "dates-mates-with-damona-hoffman",
  "boy-meets-bravo",
  "edified-minds-podcast",
  "we-re-just-slayin",
  "trackstarz",
  "messy-mommies",
  "if-i-go-missing-the-witches-did-it",
  "ph-divas",
  "jill-scott-presents-j-ill-the-podcast",
  "dmd-podcast",
  "unmothered-unbothered-podcast",
  "the-drunken-knights",
  "five-foot-five-and-growing",
  "childless-not-by-choice",
  "sassy-spirituality",
  "blessed-bossed-up",
  "house-party",
  "gems-with-genesis-amaris-kemp",
  "power-in-prose",
  "black-cowboys",
  "tiddies-and-tacos",
  "the-breaks-radio",
  "experience-points-podcast",
  "chica-travel-with-lelo",
  // "black-women-travel-podcast",
  "damn-sis-podcast",
  "unfiltered-limin-w-blt-podcast",
  "sailor-moon-fan-club",
  "random-eloquence",
  "questlove-supreme",
  "experiencing-motherhood-single-black",
  "the-85-south-show-with-karlous-miller-dc-young-fly-and-chico-bean",
  "moscato-and-tea-show",
  "but-why-tho",
  "as-f-ck-the-podcast",
  "scene-n-nerd",
  "the-roommates-podcast",
  "real-ass-affirmations",
  "the-relentless-diaries",
  "random-acts-of-podcast",
  "we-still-like-each-other",
  "the-sista-speak-show",
  "i-found-this-great-book",
  "hi-phi-nation",
  "the-sales-evangelist",
  "everybody-talks",
  "power-hour",
  "zuri-hall-s-hot-happy-mess",
  "solidarity-is-this",
  "a-daily-purpose-bible-study-amp-devotional-a-podcast-by-our-given-purpose",
  "jaye-and-hatah-podcast",
  "artifactual-journey",
  "undistracted-with-brittany-packnett-cunningham",
  "the-amanda-seales-show",
  "comedy-trap-house",
  "tech-policy-leaders",
  "jamaican-and-caribbean-weekly-news-summary",
  "screenwriters-rant-room",
  "the-unbiased-truth-podcast",
  "the-black-doctors-podcast",
  "the-access-point",
  "culture-building-like-a-pro",
  "she-did-that",
  "we-are-more-than-the-podcast",
  "i-am-athlete",
  "carefully-reckless",
  "garbled-twistory-a-us-history-podcast-told-through-elections",
  "the-undressing-room-podcast-presented-by-macy-s",
  "all-the-smoke",
  "black-men-can-t-jump-in-hollywood",
  "table-for-three-podcast",
  "edifying-together-feat-tamir-d-harper",
  "faith-walk-life-talk-podcast",
  "best-friends-with-nicole-byer-and-sasheer-zamata",
  "dreams-inspire-reality-podcast",
  "the-christopher-terrance-jones-show",
  "roy-s-job-fair",
  "enterprise-now",
  "views-from-da-friend-zone",
  "hood-grown-aesthetic",
  "the-boonie-breakdown",
  "blanime-podcast",
  "whor-eible-decisions",
  "work-on-your-game-mindset-mental-toughness-discipline-confidence-for-business",
  "take-the-lead-podcast",
  "kayla-got-the-411-show",
  "three-black-halflings",
  "have-a-seat-with-lana-hailemariam",
  "radiotopia-presents-bot-love",
  "the-voices-you-have-not-heard",
  "cultivating-h-e-r-space-uplifting-conversations-for-the-black-woman",
  "the-melanin-jay-podcast",
  "dear-culture",
  "studio-78-ignite-your-passion-amplify-productivity-achieve-success",
  "black-girl-squee",
  "back-issue",
  "nappy-boy-radio-with-t-pain",
  "no-shade-podcast",
  "the-nerd-council-podcast",
  "dam-internet-you-scary",
  "the-soul-is-prismatic",
  "intentional-queen-podcast",
  "listen-to-your-single-friend",
  "the-idea-of-manhood",
  "fanatical-fics-and-where-to-find-them-a-harry-potter-fanfiction-podcast",
  "the-opinionated-bruhtha-s",
  "the-baller-alert-show",
  "here-comes-the-break",
  "the-marc-medley-show-s-tracks",
  "margo-lovett-her-business-her-voice-her-conversation",
  "being-made-whole",
  "black-flix-likka",
  "the-first-gen-lounge",
  "black-health-365",
  "the-money-exchange",
  "the-black-guy-who-tips-podcast",
  "brb-podcasts",
  "there-are-no-girls-on-the-internet",
  "the-slowdown-poetry-reflection-daily",
  "rambling-with-egypt",
  "just-let-me-vent",
  "stance-podcast",
  "sarcasm-city-tv",
  "no-need-for-apologies-the-podcast",
  "baby-this-is-keke-palmer",
  "imperfect-strangers",
  "snob-os",
  "relatively-fit",
  "pages-from-my-notebook-a-true-to-life-podcast",
  "for-all-nerds-show",
  "hustle-in-faith",
  "r-b-money",
  "well-read-black-girl-with-glory-edim",
  "into-it-a-vulture-podcast-with-sam-sanders",
  "geeked-up",
  "the-africalypso-podcast",
  "the-roxane-gay-agenda",
  "the-thought-card",
  "the-fake-ass-book-club",
  "smooth-jazz-weekend-radio-show-w-tina-e",
  "she-gets-it-pod",
  "it-s-been-a-minute",
  "thoughts-of-an-average-joe",
  "black-women-working",
  "spiritual-homegirl",
  "two-funny-mamas",
  "married-into-crazy-with-snooks-and-lovey",
  "sisterhood-of-limitless-living",
  "louder-than-a-riot",
  "birth-stories-in-color",
  "kerning-cultures",
  "the-assignment-with-audie-cornish",
  "the-break-room",
  "the-antithesis-of-superwoman",
  "on-the-way",
  "mouthpeace-with-michael-bennett-pele-bennett",
  "oh-that-s-deep-black-women-conversations",
  "save-the-date-show",
  "you-already-know-with-kenan-thompson-and-tani-marole",
  "dinner-for-one",
  "a-shot-of-truth",
  "the-teachers-impact",
  "self-care-irl",
  "the-vexed-intellects-podcast",
  "the-digital-gumbo-podcast",
  "my-momma-told-me",
  "slay-girl-slay",
  "therapy-for-black-girls",
  "what-s-in-your-glass-with-carmelo-anthony",
  "trill-mba-show-the-career-management-podcast-for-black-women",
  "extra-gravy",
  "where-my-girls-at",
  "keepin-it-rel-with-young-wayne",
  "sisters-in-loss-podcast-miscarriage-pregnancy-loss-infertility-stories",
  "time-and-relative-blackness-in-space",
  "mind-over-melanin-podcast",
  "black-history-year",
  "the-ani-mates-podcast",
  "for-realness-sake",
  "our-body-politic",
  "the-know-for-sure-pod",
  "markeisha-hall-s-podcast",
  "sensual-self-with-ev-yan-whitney",
  "that-black-couple-podcast",
  "ino-podcast",
  "hashtag-blackout-podcast",
  "shit-black-girls-watch",
  "how-to-take-a-break",
  "liberated-love-notes",
  "cases-of-color",
  "the-plastic-couch",
  "the-dope-black-chick",
  "sistahs-connect-conversations-that-celebrate-and-inspire-black-women",
  "new-wabu-order",
  "get-crowdfunded-now",
  "purple-diary-by-danielle-richardson",
  "carefree-and-black-diaries",
  "hey-baby-live",
  "my-sisters-glo",
  "cre-wcial-conversations",
  "high-tea-with-mel-kel-kay",
  "made-in-indy",
  "manifest-daily",
  "get-candid-podcast",
  "jalen-jacoby",
  "awkward-adventures-of-a-college-graduate",
  "mental-hel-lth",
  "relationship-passa-passa",
  "the-navy-black-podcast",
  "redesigned-podcast",
  "mango-tea-podcast",
  "the-s-anthony-thomas-show",
  "flagrant-2-w-d-murph",
  "the-black-umbrella",
  "on-the-ride-up",
  "black-millennial-marriage",
  "the-barbershop",
  "peeps-creek-the-cafe",
  "the-cup-of-glo-podcast",
  "plot-twist",
  "the-nerds-with-accents",
  "the-run-wave",
  "carefree-black-nerd-podcast",
  "dangerously-likely",
  "cottage-in-the-court",
  "from-da-bottom-podcast",
  "chit-chat-connoisseur",
  "chronicles-of-devine-destiny-podcast",
  "blerd-ish",
  "the-not-so-private-podcast",
  "docs-outside-the-box",
  "all-things-melanin",
  "shaping-the-shift-podcast",
  "invisible-black-girl",
  "not-your-auntie-s-podcast",
  "the-tv-tea-podcast",
  "coffee-convos",
  "the-positive-professional",
  "the-other-room",
  "korem-podcast",
  "the-colors-of-her-success",
  "geekset",
  "9-5-otp",
  "seeing-color",
  "civic-cipher",
  "him",
  "a-thousand-eyes-and-one",
  "off-the-record",
  "fearless-freedom-with-dr-g",
  "between-sessions-podcast",
  "dem-black-mamas-podcast",
  "young-black-opinionated",
  "circuits-of-change-barbados-future-in-tech",
  "living-corporate",
  "creator-to-creators-with-meosha-bean",
  "seizing-freedom",
  "a-perspective-w-aaron-ashley",
  "sweetened-with-ash-lemonade",
  "anime-junkies-three-weebs-and-a-podcast",
  "great-moments-in-weed-history",
  "how-they-did-it-and-why",
  "discover-music-channel-discover-music-channel",
  "hip-hop-social-worker-multi-media",
  "the-michelle-obama-podcast",
  "social-proof-podcast",
  "jamaican-state-of-mind",
  "dos-yonkos",
  "catch-a-fire-podcast-coffee-for-your-soul",
  "chat-bout-flim",
  "moore-love-connection",
  "black-married-nerds",
  "remotely-speaking-up",
  "conversations-with-katy",
  "black-girl-interrupted-podcast",
  "hello-seven-podcast",
  "but-what-do-i-know",
  "the-late-night-flight",
  "struggling-but-surviving",
  "the-daily-show-podcast-universe",
  "the-reality-is",
  "the-laverne-cox-show",
  "center-her-power",
  "on-thee-other-side-w-mark-oshea",
  "trap-talk",
  "let-s-be-real-sports",
  "ratchetthoughtsandisms",
  "mental-matters-podcast",
  "b-2-u-podcast",
  "thee-scattered-podcast",
  "a-woman-of-her-word-podcast",
  "the-well-fit-lab",
  "the-creature-connection",
  "sol-affirmations-with-karega-felicia",
  "stakes-is-high-podcast",
  "marketing-for-the-culture",
  "1972",
  "rns-podcast",
  "food-heaven-podcast",
  "gt-radio-the-geek-therapy-podcast",
  "the-john-effect-podcast",
  "blerd-city",
  "tales-of-the-text",
  "you-get-a-podcast",
  "up-on-game-presents",
  "spilled-milk",
  "blerd-s-eyeview",
  "pressed",
  "jade-x-d",
  "founders-unfound",
  "big-talk-podcast",
  "friend-forward",
  "the-chenese-lewis-show",
  "where-s-my-40-acres-podcast",
  "not-your-mama-s-gamer",
  "why-won-t-you-date-me-with-nicole-byer",
  "fiya-starter-com-podcast",
  "smr-podcast",
  "po-politickin",
  "wud-up-doe-podcast",
  "the-poplife-podcast",
  "fo-get-dat-yo",
  "in-the-conversation",
  "it-s-all-about-health-fitness",
  "switch-pivot-or-quit",
  "studio-flow",
  "bro-talk-live",
  "the-show",
  // "the-tariq-elite-radio-show",
  "let-s-talk-about-it",
  "better-2-gether-with-dennis-and-loni-c",
  "live-life-aggressively-podcast-w-mike-mahler-sincere-hogan",
  "it-s-our-show-hiphop-for-people-that-know-better",
  "the-jenkins-jonez-podcast",
  "durags-and-boatshoes",
  "the-suga",
  "gaming-and-then-some",
  "podcast-fandom",
  "while-black",
  "uncolonized",
  "brown-vegan",
  "meech-speaks",
  "code-switch",
  "corner-table-talk",
  "navigating-the-customer-experience",
  "black-wall-street-today-with-blair-durham",
  "dare-to-pursue",
  "the-no-nonsense-show-a-funny-experiment-in-black-experience",
  "six-weeks-to-fitness",
  "tv-movie-mistress",
  "women-entrepreneurs-radio",
  "the-brown-liquor-report",
  "the-new-black-panther-party",
  "let-s-talk-bruh",
  "the-jock-and-nerd-podcast",
  "minorities-in-publishing",
  "not-your-average-netizens",
  "the-conversation",
  "the-spann-report-podcast-network",
  "mogul-motivation",
  "the-baseline-nba-podcast",
  "the-institute-of-black-imagination",
  "the-his-and-her-money-show",
  "the-build-on-beauty-podcast",
  "the-captain-s-couch",
  "fish-tea-podcast",
  "two-black-guys-with-good-credit",
  "the-et-cs-with-kevin-durant",
  "we-are-crayons-the-podcast",
  "black-entrepreneur-blueprint",
  "wizard-team-a-black-fantasy-podcast",
  "follow-the-leader",
  "some-of-my-best-friends-are",
  "the-brown-girls-guide-to-politics",
  "the-gayly-prophet-a-harry-potter-podcast",
  "drinking-partners",
  "the-intentionally-made-podcast",
  "island-jazz-chat",
  "bill-rhoden-on-sports-bro-spod",
  "the-need-to-know-podcast",
  "home-grown-radio",
  "infertility-and-me-uncut-stories-of-infertility-survivors",
  "colour-out-the-box",
  "the-sneaker-box",
  "the-awkward-minority-podcast",
  "trials-to-triumphs",
  "what-we-talking-bout-podcast",
  "the-self-love-fix",
  "the-stacks",
  "pod-save-the-people",
  "straight-up-with-trent-shelton",
  "young-black-bothered",
  "in-godfrey-we-trust",
  "we-talk-back",
  "spit-sessions",
  "reparations-the-big-payback",
  "pivot-with-purpose",
  "black-hair-in-the-big-leagues",
  "the-black-health-academy",
  "real-estate-investor-goddesses-podcast",
  "minority-korner",
  "dope-dealers",
  "the-ash-cash-show",
  "holding-court-with-eboni-k-williams",
  "fre-eda-s-world",
  "solo-moms-talk",
  "say-yes-with-carla-hall",
  "geek-therapy-radio-podcast",
  "tactless-knowit-alls",
  "laugh-learn",
  "coinology-the-podcast",
  "nice-to-meet-you",
  "3-piece-set-podcast",
  "the-pum-pum-chronicles",
  "the-336-pull-up-podcast",
  "adulting-with-michelle-buteau-and-jordan-carlos",
  "the-q-chat-podcast",
  "maya-s-collection-of-love-stories-and-more",
  "the-way-with-jazz-and-tae",
  "black-women-talk-work",
  "those-guys-over-there",
  "grown-women-gaming",
  "the-ebs-podcast",
  "hotboxin-with-mike-tyson",
  "children-of-the-internet",
  "a-taste-to-consider-pod-cast",
  "conversation-for-adults",
  "the-takeaway",
  "gft-radio-network",
  "unapologetic-advice-podcast",
  "k-is-4-knowledge",
  "militantly-mixed",
  "the-reset-podcast",
  "de-colores-radio",
  "potstirrer-podcast",
  "social-selling-made-simple",
  "chat-sh-t-the-official-rap-sh-t-podcast",
  "lemon-pepper-wet-podcast",
  "the-bam-cast",
  "talk-is-talk",
  "the-secret-life-of-canada",
  "dope-on-the-table-podcast",
  "the-homecoming-podcast-with-dr-thema",
  "xo-necole-podcast",
  "see-life-different",
  "inspirational-vibes-with-jimjam",
  "the-trap-nerds-podcast",
  "social-complex",
  "somewhere-over-the-rainbow-podcast",
  "somewhere-between-woke-well",
  "become-she",
  "annoyed-not-offended",
  "city-living-with-churchill",
  "go-hard-chick",
  "money-talk-with-tiff",
  "ed-talks-daily-holistic-lifestyle-and-motivation",
  "a-date-with-darkness-podcast",
  "discussing-trek-star-trek",
  "ok-girl-podcast",
  "how-to-survive-the-end-of-the-world",
  "cabronas-y-chingonas",
  "the-lituation-room",
  "clash-the-sibling-rivalry-podcast",
  "guys-next-door",
  "this-prof-life-podcast-women-of-color-in-higher-education",
  "decoding-40",
  "schoolin-life-podcast",
  "iced-coffee-and-self-care",
  "the-afr-otaku-podcast",
  "minority-report-podcast",
  "headwraps-and-lipsticks",
  "diversified-game",
  "little-gold-nuggets",
  "the-hats-podcast",
  "say-less-with-the-culture",
  "mind-flowers",
  "the-black-girl-essentials",
  "diary-of-a-mad-black-man",
  "the-rewine",
  "fck-work-but-ima-go",
  // "ethnically-ambiguous",
  "bad-romance-podcast",
  "crossroads-podcast",
  "the-stephen-a-smith-show",
  "the-patdown-with-ms-pat",
  "noir-true-crime-files-podcast",
  "dear-franny",
  "manga-in-your-ears",
  "millennial-livin-a-professional-perspective",
  "creatively-exposed",
  "stitch-please",
  "karukerament-the-english-version",
  "the-socially-unacceptable-podcast",
  "the-sub-urban-podcast",
  "coffee-and-books",
  "black-radical-queer",
  "views-of-an-ex",
  "black-love-matters",
  "slaying-self-doubt-podcast",
  "ignorant-bliss",
  "real-talk-with-q-alexander",
  "journey-to-jupiter-discover-the-life-you-ll-create",
  "the-class-prefects",
  "what-s-she-on-about",
  "flourish-with-bijul",
  "the-mighty-metahumans",
  "throwdown-show",
  "while-they-nap",
  "truly-unruly-with-marcus-jessica-trufant",
  "rickey-smiley-morning-show-podcast",
  "the-redrock-podcast",
  "the-entrepreneurial-you",
  "geto-boys-reloaded",
  "bgnyc",
  "the-malcolm-effect",
  "the-cocktail-break",
  "theblerdgurl-podcast-with-karama-horne",
  "causeascene",
  "groundings",
  "queer-woc",
  "oprah-s-super-soul",
  "friends-like-us",
  "never-left-college",
  "notes-from-america-with-kai-wright",
  "octavia-s-parables",
  "kinks-with-ke-ke",
  "the-black-expat-podcast",
  "the-color-grade",
  "the-creative-block",
  "and-then-we-had-sex",
  "the-hey-girl-podcast",
  "the-amazing-truth-podcast",
  "girl-f-you",
  "hella-black-podcast",
  "south-side-of-wakanda",
  "the-friend-zone",
  "the-joe-budden-podcast",
  "2-dudes-and-a-chick",
  "tequila-tlk",
  "side-hustle-pro",
  "fanti",
  "bff-with-the-chef",
  "tea-with-queen-and-j",
  "tech-is-the-new-black-with-cyrus",
  "tax-season",
  "noire-histoir",
  "girl-i-guess",
  "turnt-30-podcast",
  "late-night-with-ler-and-lionel",
  "carry-on-friends-the-caribbean-american-podcast",
  "u-nestablished-podcast",
  "the-hard-or-soft-show",
  "more-than-cultr-the-pod-cast",
  "here-s-the-thing",
  "the-bully-and-the-beast",
  "blue-dollar-podcast",
  "the-black-girl-bravado",
  "grown-and-growing-podcast",
  "say-your-mind",
  "re-cappin-with-delora-ashley-podcast",
  "landlord-in-stilettos",
  "creative-habits-podcast",
  "cheers-queers",
  "people-s-party-with-talib-kweli",
  "black-girls-eating",
  "tha-team-podcast",
  "behind-the-brilliance",
  "the-draymond-green-show",
  "redefining-wealth-with-patrice-washington",
  "same-energy-podcast",
  "song-versation",
  "black-girl-gone-a-true-crime-podcast",
  "the-bakari-sellers-podcast",
  "marriage-be-hard-conversations",
  "black-married-debt-free-podcast",
  "le-var-burton-reads",
  "black-at-work",
  "getting-animated",
  "the-stoop",
  "self-care-and-chill-with-maui",
  "hoodoo-plant-mamas",
  "the-minority-trailblazer-podcast-with-greg-e-hill",
  "this-is-good-for-you",
  "montgomery-co",
  "gettin-grown",
  "balanced-black-girl",
  "big-boy-on-demand",
  "the-read",
  "african-folktales-podcast",
  "go-off-sis",
  "dead-ass-with-khadeen-and-devale-ellis",
  "the-reid-out-with-joy-reid",
  "both-sides-of-the-bed",
  "intersectionality-matters",
  "black-wealth-renaissance",
  "journey-to-launch",
  "food-4-thot",
  "in-the-moement",
  "black-power-moves",
  "she-wolf-alchemy-podcast",
  "this-might-not-go-well",
  "truth-be-told",
  "the-courtney-sanders-podcast",
  "insecuritea-the-insecure-aftershow",
  "toure-show",
  "c-mon-son-the-podcast",
  "self-care-after-dark",
  "trans-lash-podcast-with-imara-jones",
  "what-s-in-it-for-us",
  "millionaire-mindsets",
  "2-wo-peez-and-a-bong-somebody-s-favorite-podcast",
  "terry-roseland-podcast",
  "the-idealists",
  "the-cut",
  "petty-party-podcast",
  "so-problematic",
  "i-hope-this-don-t-get-me-in-trouble",
  "still-processing",
  "big-facts-with-big-bank-dj-scream",
  "lovers-and-friends-with-shan-boodram",
  "on-one-with-angela-rye",
  "love-respect-with-killer-mike",
  "dreams-in-drive",
  "black-girl-seoul",
  "brown-girl-self-care",
  "snap-judgment",
  "pivot-podcast",
  "black-girls-heal",
  "like-a-real-book-club",
  "200-and-counting",
  "the-mental-wealth-show-with-rich-jones",
  "don-t-call-me-white-girl",
  "small-doses-with-amanda-seales",
  "black-girl-nerds",
  "thinking-outside-the-boombox",
  "the-fckry-with-leslie-jones-and-lenny-marcus",
  "that-s-what-i-m-saying-the-podcast",
  "total-r-b-podcast",
  "the-bawse-mom-movement-podcast",
  "scam-goddess",
  "thatblackboyjoy",
  "black-me-up-podcast",
  "shxts-n-gigs",
  "the-happy-room",
  "one-a-potter-s-house-church",
  "larry-wilmore-black-on-the-air",
  "geektastiq-cypha",
  "bruh-it-s-a-pod-cast",
  "around-the-way-curls",
  "talk-to-us-nice",
  "the-ordinary-show",
  "truth-talks-with-tiff",
  "like-a-real-boss",
  "care-free-black-girl",
  "rap-order",
  "talk-loud-with-me",
  "more-than-that-with-gia-peppers",
  "so-shameless",
  "dapper-j-bobby-george-presents-1000-jumpers",
  "the-right-time-with-bomani-jones",
  "30-to-life-redefining-the-black-experience",
  "liquor-talk",
  "long-story-short",
  "ever-after-with-jaleel-white",
  "the-curry-gumbo-podcast",
  "hi-felicia",
  "girl-stop-playin-podcast",
  "first-comes-love-then-comes-marriage",
  "you-heard-me-write",
  "kontent-queens",
  "the-brilliant-idiots",
  "finding-our-way",
  "maino-presents-kitchen-talk",
  "you-had-me-at-black",
  "getting-your-sh-t-together",
];

const shuffle = (arr: any[]) =>
  arr
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);

const HomePage = () => {
  return (
    <Box
      onContextMenu={(e) => {
        e.preventDefault();
        return false;
      }}
    >
      <Box className={style["photo-grid-container"]}>
        <Box
          className={"animate__animated animate__fadeIn"}
          position={"absolute"}
          zIndex={99999}
          top={"45%"}
          left={"50%"}
          transform={"translate(-50%, -50%)"}
          px={{ base: 1, md: 3 }}
          maxW={{ base: "350px", md: "450px" }}
          textAlign={"center"}
        >
          <Box
            py={4}
            display="flex"
            justifyContent="center"
            flexDir="column"
            bgColor="#ee9900"
            borderRadius={"30px"}
            boxShadow="dark-lg"
          >
            {" "}
            <Text fontSize="sm" fontStyle="italic">
              UPDATES ARE BEING MADE
            </Text>
            <Image
              src="https://cdn.pdcsts.co/assets/bpd_circle_letter_transparent.png"
              width="auto"
              height="auto"
              objectFit={"contain"}
              maxW="400px"
              onDragStart={() => {
                return false;
              }}
            />
            <Heading fontSize="3xl">Black Podcast Directory</Heading>
            <HStack justifyContent={"center"}>
              <ButtonGroup size="md" isAttached variant="link" my={6}>
                {/* <Button
                color="black"
                bgColor="#ffffff40"
                as={Link}
                href="https://pdcsts.co/blkpdcstdir/newsletter/"
                p="2"
                leftIcon={<TiChevronLeft />}
                mr={2}
              >
                Newsletter
              </Button> */}
                <Button
                  color="black"
                  bgColor="#ffffff40"
                  as={Link}
                  href="http://blackpodcastdirectory.com/submit-podcast"
                  p="2"
                  rightIcon={<TiChevronRight />}
                >
                  List Your Podcast!
                </Button>
              </ButtonGroup>
            </HStack>
          </Box>

          <Box
            mt={2}
            p={3}
            boxShadow="dark-lg"
            bgColor="#ee9900"
            borderRadius={"30px"}
          >
            <Box
              dangerouslySetInnerHTML={{
                __html: `
			<!-- Begin Mailchimp Signup Form -->
			<div id="mc_embed_signup">
				<form
					action="https://blackpodcastdirectory.us13.list-manage.com/subscribe/post?u=e9d9c9fca56026248198b134e&amp;id=404f37255f"
					method="post"
					id="mc-embedded-subscribe-form"
					name="mc-embedded-subscribe-form"
					class="validate"
					target="_blank"
					novalidate=""
				>
					<div class="newsletter_header">
						<h1 align="center">
							<font style="vertical-align: inherit">
								<font style="vertical-align: inherit">
                <span style="font-weight: 300">Join</span>
									<span style="font-weight: bold; font-size: 1.5em; color: #9c3b3b">The Stay Up</span>
									<span style="font-weight: 300">Newsletter</span>
								</font>
							</font>
						</h1>
					</div>
					<div id="mc_embed_signup_scroll" style="display: flex; justify-content: center">
						<input type="email" value="" name="EMAIL" class="email" id="mce-EMAIL" placeholder="Enter your email" required="" style="margin-right: 10px; padding: 5px 10px;border-radius: 10px; font-weight: bold" />
						<!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
						<div style="position: absolute; left: -5000px" aria-hidden="true"><input type="text" name="b_e9d9c9fca56026248198b134e_404f37255f" tabindex="-1" value="" /></div>
						<div class="clear_">
							<font style="vertical-align: inherit">
								<font style="vertical-align: inherit"><input type="submit" value="Sign Up" name="subscribe" id="mc-embedded-subscribe" class="button" style="padding: 5px 10px;border-radius: 10px; font-weight: bold; background-color: gray; color: white" /></font>
							</font>
						</div>
					</div>
				</form>
			</div>
			<!--End mc_embed_signup-->`,
              }}
            ></Box>
            <HStack spacing="6" justifyContent={"center"}>
              <Link>
                <GrInstagram fontSize={"2em"} />
              </Link>
              <Link>
                <GrFacebookOption fontSize={"2em"} color="#000" />
              </Link>
              <Link>
                <GrTwitter fontSize={"2em"} />
              </Link>
              <Link>
                <GrMail fontSize={"2em"} />
              </Link>
            </HStack>
          </Box>
        </Box>
        {["left", "right", "left", "right", "left"].map(
          (dir: string, index: number) => (
            <Flex
              className={style[`photo-grid-${dir}`]}
              key={index}
              // backgroundImage={`url(/${index}.jpg)`}
              maxH={"185px"}
            >
              {chunk(shuffle(covers), 50)[0].map((img: any, idx: number) => (
                <Image
                  key={idx}
                  alt={img}
                  src={`https://cdn.pdcsts.co/blkpdcstdir/shows/${img}/${img}-medium.jpg`}
                  width="auto"
                  height="auto"
                  objectFit={"contain"}
                  border="5px solid #fff"
                  opacity=".5"
                />
              ))}
            </Flex>
          )
        )}
      </Box>
      <HStack spacing={3} justifyContent={"center"}>
        <Text>&copy; 7sixty3, LLC</Text> <Text>&bull;</Text>
        <Link href="/about/terms-conditions"> terms </Link>
      </HStack>
    </Box>
  );
};

export default HomePage;
