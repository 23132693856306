import { Routes, Route } from "react-router-dom";

import HomePage from "pages/home";
import TermsConditions from "pages/about/terms-conditions";
import NotFound from "pages/error";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about/terms-conditions" element={<TermsConditions />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Router;
